const oktaConfig = {
  issuer: process.env.REACT_APP_OKTA_ISSUER as string,
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID as string,
  redirectUri: window.location.origin + "/login/callback",
  tokenManager: {
    autoRenew: true,
    secure: true,
    storage: "sessionStorage",
  },
};

export const config = {
  okta: oktaConfig,
  backendURL: process.env.REACT_APP_BACKEND_URL!,
  preferredUsername: "", // Keep empty at init
  setPreferredUsername: function (username: string) {
    this.preferredUsername = username;
  },
  clientOrganizationId:
    process.env.REACT_APP_CLIENT_ORGANIZATION_ID || "MILLENIUM", // Default to 'MILLENIUM' if not specified
  clientAccessToken: "",
  setClientAccessToken: function (access_token: string) {
    this.clientAccessToken = access_token;
  },
};
console.log(config);
