import { defaultBotDescription } from "../api/defaults_fallback";
import { FeedbackThumbs, botDescription } from "../api/typeApi";

export interface Message {
    id: string;
    contenu: string;
    date: string;
    user: string;
    idChat: string,
    feedback?: FeedbackThumbs;
}

export interface Bot extends botDescription {
    idChat: string;
    isLoading: boolean;
    messages: Message[];
}

export const emptyBot: Bot = {
    ...defaultBotDescription,
    "idChat": "",
    "isLoading": false,
    "messages": []
};