import React from 'react';
import styled from "styled-components";
import {botDescription} from "../../api/typeApi";
import {Button} from "@fonciastark/duplo";

const StylesContainerQuestion = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

const StylesButton = styled.div`
    padding-top: 10px;
    width: 80%;
`;

interface Props {
    addNewMessage: (inputValue : string) => void;
    selectedBot: botDescription
}

export const Ask : React.FC<Props> = ({addNewMessage, selectedBot}) => {

    return (
        <StylesContainerQuestion>
            {
                selectedBot.display_examples.map(
                    ask =>
                        <StylesButton key={ask}>
                            <Button
                                onClick={()=>addNewMessage(ask)}
                                fullWidth={true}
                                size={"xsmall"}
                                kind={"default"}
                            >
                                {ask}
                            </Button>
                        </StylesButton>
                )
            }
        </StylesContainerQuestion>
    )
};