import { IconButton, Tooltip } from "@fonciastark/duplo";
import { mdiRefresh } from "@mdi/js";
import React from 'react';
import styled from "styled-components";
import { Bot } from '../data/bot';
import { SelectBot } from "./Header/SelectBot";
import ProfileBubble from "./commun/Profile";

interface Props {
    bot: Bot,
    bots: Bot[],
    setSelectedBotName: (inputValue: string) => void,
    setReset: (inputValue : boolean) => void,
}

const StylesHeader = styled.div`
    background: #317AC1;
    display: flex;
    align-items: center;
`;

const StylesIcon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 10px;
    padding-left: 10px;
`;

const StylesRefresh = styled.div`
    display: flex;
    align-items: center;
    justify-content: end;
`;

export const Header: React.FC<Props> = ({ bot, bots, setSelectedBotName, setReset }) => {

    return (
        <StylesHeader>
            <StylesIcon>
                {bot.name !== "EMPTY_BOT" && <ProfileBubble size={35} name={bot.name}/>}
            </StylesIcon>
            <SelectBot bot={bot} bots={bots} setSelectedBotName={setSelectedBotName} />
            <Tooltip title="Réinitialisation">
                <StylesRefresh>
                    <IconButton
                        onClick={() => setReset(true)}
                        iconPath={mdiRefresh}
                        iconSize={1.5}
                        color={"#e6e6fa"}
                    />
                </StylesRefresh>
            </Tooltip>
        </StylesHeader>
    );
};

