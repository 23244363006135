import { config } from "../config";
import { defaultBotDescription, getRandomErrorMessage } from "./defaults_fallback";
import {
    botDescription,
    botRequestBody,
    botRequestResponse,
    feedbackBody,
    feedbackResponse,
    generateResponse,
    ragBody
} from "./typeApi";



const postAPI = async (requestBody:(ragBody|feedbackBody|botRequestBody), route:string, authState:any) => {

    if (!config.preferredUsername) {
        console.error('preferredUsername is not set');
        return;
    }
    // const urlParams = new URLSearchParams(window.location.search);
    // let token = urlParams.get('token');
    let token = config.clientAccessToken;
    let authorization = "Bearer "

    if (token) {
        authorization += token
    } else {
        if (authState.isAuthenticated) {
            authorization += authState.accessToken.accessToken
        }
    }
    try {
        const response = await fetch(config.backendURL+route, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': authorization,
            },
            body: JSON.stringify(requestBody)
        });
        let output_response = await response.json();
        return output_response
    } catch (error) {
        console.error('Fetch error:', error);
        throw error;
    }
}


const postGenerate = async (
    inputValue : string, 
    botName: string, 
    route: string,
    client: string,
    authState:any,
    idChat: string,
) : Promise<generateResponse> => {
    const requestBody : ragBody  = {
        user_id: config.preferredUsername,
        message: inputValue,
        bot: botName,
        client: client
    };
    if (idChat!=="") {
        requestBody["chat_id"] = idChat
    }

    try {
        const responseData = await postAPI(requestBody, route, authState);
        return {
            response: responseData.response,
            chat_id: responseData.chat_id,
            request_id: responseData.request_id
        };
    } catch (error) {
        console.error("Error calling API:", error);

        return {
            response: getRandomErrorMessage(),
            chat_id: idChat ?? "",
            request_id: Math.random()+""+Math.random()
        };
    }
};



const postFeedback = async (feedbackBody : feedbackBody, authState:any) : Promise<feedbackResponse> => {
    try {
        let responseData = await postAPI(feedbackBody, "/feedback", authState);
        return {
            statusCode: responseData.statusCode,
            body: responseData.body
        };

    } catch (error) {
        console.error("Error calling API:", error);
        return {
            statusCode: 400,
            body: "Feedback Request not handled"
        };
    }
};

const postListBots = async (client_id : string, organisation_id : string, authState:any) : Promise<botRequestResponse> => {
    try {
        let requestBody : botRequestBody = {
            "user_id": client_id,
            "client": organisation_id
        }
        const response = await postAPI(requestBody, "/bot", authState);
        let list_bots: botDescription[] = response.bots
        return {
            body : list_bots
        };
    } catch (error) {
        console.error("Error calling API:", error);

        return {
            body : [defaultBotDescription]
        };
    }
};

export { postFeedback, postGenerate, postListBots };

