import React from 'react';
import ReactMarkdown from "react-markdown";
import styled from 'styled-components';
import { Bot, Message } from '../../data/bot';
import ProfileBubble from '../commun/Profile';
import { FeedbackResponse } from "./Feedback";

const StyledIcon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center; 
    padding-right: 10px;
`;

interface Props {
    message: Message,
    isHovered: boolean,
    updateBotMessage: (bot: Bot, message: Message) => void,
    selectedBot: Bot,
    isLatestMessage: boolean
}

const StyledText = styled.div`
    border-radius: 8px;
    min-height: 41px;
`;


const StylesFeedback = styled.div`
    transition: opacity 0.2s ease;
    display: flex;
    align-items: center;
    opacity: 0;
    visibility: hidden;
`;


const StylesLoadingMessage = styled.div`
    font-size: medium;
    color: #5c6a82;
`;


export const Bulle: React.FC<Props> = ({ message, isHovered, updateBotMessage, selectedBot, isLatestMessage }) => {
    let showFeedback;
    if (message.user === 'bot') {
        if (isLatestMessage || isHovered) {
            showFeedback = true;
        } else {
            showFeedback = false;
        }
    };
    const showAwaitingResponse = isLatestMessage && message.user !== "bot" && selectedBot.isLoading;
    const FeedbackComponent = () => {
        return (
            <StylesFeedback style={{
                opacity: 1,
                visibility: 'visible'
            }}>
                {message.user === 'bot' && 
                    <FeedbackResponse 
                        message={message}
                    updateBotMessage={updateBotMessage}
                        selectedBot={selectedBot}
                    />}
            </StylesFeedback>
        )
    }

    const WaitMessageComponent = () => {
        return (
            <StylesLoadingMessage>
                Laissez-moi réfléchir quelques instants . . .
            </StylesLoadingMessage>
        )
    }
    

    return (
        <div style={{
            display: "flex",
            alignItems: 'flex-start',
        }}>
            <StyledIcon>
                {message.user === 'bot' ? <ProfileBubble size={32} name={selectedBot.name} /> : <ProfileBubble size={32} name="USER" />}
            </StyledIcon>
            <div style={{alignItems: "left", fontSize: "medium", paddingTop: "6px"}}>
                <h4 style={{ margin: 0 }}>
                    {message.user === 'bot' ? ("Sirius : " + selectedBot.display_name) : 'Vous'}
                </h4>
                <StyledText style={{ color: '#111' }}>
                    <ReactMarkdown children={message.contenu}/>
                </StyledText>
                <div
                style={
                    {minHeight: "41px"}
                }>
                    {showFeedback && <FeedbackComponent/>}
                    {showAwaitingResponse && <WaitMessageComponent/>}
                </div>
            </div>
        </div>
    );
};
