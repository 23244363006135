export interface ragBody {
    user_id: string;
    message: string;
    bot: string;
    client: string;
    chat_id?:string
}

export interface generateResponse {
    request_id: string,
    response: string,
    source?: string[],
    chat_id: string,
}

export enum FeedbackThumbs {
    "THUMBS_UP" = "THUMBS_UP",
    "THUMBS_DOWN" = "THUMBS_DOWN"
}
export interface feedbackBody {
    "request_id": string,
    "feedback": FeedbackThumbs,
}

export interface feedbackResponse {
    statusCode: number,
    body: string
}

export interface botRequestBody {
    "user_id": string,
    "client": string
}

export interface botDescription {
    "name": string,
    "client": string,
    "route": string,
    "display_name": string,
    "description": string,
    "welcome_sentence": string,
    "display_default": boolean,
    "display_examples": string[]
}

export interface botRequestResponse {
    body: botDescription[]
}