import { botDescription } from "./typeApi"

export const defaultBotDescription : botDescription = {
    "name":"EMPTY_BOT",
    "client": "MILLENIUM",
    "route": "chat",
    "display_name": "",
    "description": "",
    "welcome_sentence": "",
    "display_default": true,
    "display_examples": []
};


const errorMessages: Array<string> = [
    "Oups ! Il semble que je sois en mode sieste. Je vais peut-être avoir besoin d'une petite mise à jour de caféine pour redémarrer. 😴☕️",
    "Ah, la technologie... parfois, elle prend des pauses inattendues. Je me suis peut-être égaré dans le cyberespace. Mais ne vous inquiétez pas, je vais revenir bientôt avec une carte plus précise ! 🌌🗺️",
    "Désolé, je suis temporairement indisponible pour des raisons techniques. Je serai de retour dès que j'aurai rechargé mes batteries virtuelles. ⚡️🤖",
    "Ah, la magie de l'informatique ! Parfois, même les bots ont besoin d'une petite pause pour réfléchir à la vie binaire. Je travaille sur mon éclair de génie. ⚡️💭"
]

export function getRandomErrorMessage() {
    const randomIndex = Math.floor(Math.random() * errorMessages.length);
    return errorMessages[randomIndex];
  }
  