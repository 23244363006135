import React from 'react';
// @ts-ignore
import USER from "../../assets/USER_ICON.png";
// @ts-ignore
import SIRIUS from "../../assets/SIRIUS.png";
// @ts-ignore
import SIRIUS_ESSET from "../../assets/SIRIUS_ESSET.png";
// @ts-ignore
import SIRIUS_LEGAL from "../../assets/SIRIUS_LEGAL.png";
// @ts-ignore
import SIRIUS_IT from "../../assets/SIRIUS_IT.png";
// @ts-ignore
import SIRIUS_ML from "../../assets/SIRIUS_ML.png";
// @ts-ignore
import SIRIUS_COMM from "../../assets/SIRIUS_COMM.png";
// @ts-ignore
import SIRIUS_TICKET from "../../assets/SIRIUS_TICKET.png";
// @ts-ignore
import SIRIUS_ANNONCE from "../../assets/SIRIUS_ANNONCE.png";

interface Props {
    size: number,
    name: string
}


const ProfileBubble: React.FC<Props> = ({size, name}) => {

    let sizePixel:string = size + "px"
    let icon;
    if (name === 'USER') {
        icon = USER
    } else if (name === 'CHAT') {
        icon = SIRIUS
    } else if (name === 'EMPTY_BOT') {
        icon = SIRIUS
    } else if (name === 'ESSET') {
        icon = SIRIUS_ESSET
    } else if (name === 'LEGAL') {
        icon = SIRIUS_LEGAL
    } else if (name === 'MILLENIUM') {
        icon = SIRIUS_ML
    } else if (name === 'POWIMO') {
        icon = SIRIUS_ML
    } else if (name === 'FONCIA_IT') {
        icon = SIRIUS_IT
    } else if (name === 'FONCIA_IT_EMPLOYEE') {
        icon = SIRIUS_IT
    } else if (name === 'COMMUNICATION') {
        icon = SIRIUS_COMM
    } else if (name === 'REFORMULATION_TICKET'){
        icon = SIRIUS_TICKET        
    } else if (name === 'REFORMULATION_ANNONCE') {
        icon = SIRIUS_ANNONCE
    } else {
        icon = SIRIUS
        console.log("Add profile picture for bot " + name)
    }
    return (
        <img
            src={icon}
            alt="Sirius Bot Profile"
            style={{ width: sizePixel, height: sizePixel}}
        />
    );
};

export default ProfileBubble;

