import { AccessToken, IDToken } from "@okta/okta-auth-js";
import { useOktaAuth } from "@okta/okta-react";
import moment from "moment/moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { postGenerate, postListBots } from "../api/api";
import { getRandomErrorMessage } from "../api/defaults_fallback";
import { botDescription } from "../api/typeApi";
import { ChatAccueil } from "../components/Accueil/ChatAccueil";
import { Chatbot } from "../components/ChatBot/Chatbot";
import { Footer } from "../components/Footer";
import { Header } from "../components/Header";
import { config } from "../config";
import { Bot, Message, emptyBot } from "../data/bot";

const StyledApp = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const StyledHeader = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
`;

const StyledAppMain = styled.div`
  display: flex;
  padding-top: 60px;
  height: 98vh;
  padding-bottom: 70px;
  overflow: hidden;
`;

const StyledChatbot = styled.div`
  width: 80%;
  padding-top: 10px;
  padding-bottom: 10px;
  overflow-y: auto;
  margin: 0 auto;
`;

const Sirius: React.FC = () => {
  const { authState } = useOktaAuth();
  const [logged, setLogged] = useState(false);
  const [bots, setBots] = useState<Bot[]>([]);
  const [selectedBotName, setSelectedBotName] = useState<string>("");
  const [reset, setReset] = useState<boolean>(false);
  const resetRef = useRef(reset);

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      setLogged(false);
    } else {
      let accessToken = authState.accessToken as AccessToken;
      config.setClientAccessToken(accessToken.accessToken);
      let idToken = authState.idToken as IDToken;
      let name = idToken.claims.preferred_username as string;
      config.setPreferredUsername(name);
      setLogged(true);
    }
    console.log("logged: ", logged);
  }, [authState, logged]); 

  let selectedBot =
    (bots.find((oneBot) => oneBot.name === selectedBotName) as Bot) ?? emptyBot;
  const selectedBotRef = useRef<Bot>(selectedBot);

  //IdMessage global qui s'increment lors de l'ajout d'un message (key)
  let idMessage = 0;

  // Update resetRef whenever reset changes to True
  useEffect(() => {
    if (reset) {
      resetRef.current = reset;
    }
  }, [reset]);

  //Récupération des bases de connaissances et donc de la base par default
  useEffect(() => {
    // CHECKED
    const getBotDescription = async () => {
      return await postListBots(
        config.preferredUsername,
        config.clientOrganizationId,
        authState
      );
    };

    try {
      getBotDescription().then((response) => {
        const botDescriptions = response.body || [];
        const botsInit: Bot[] = botDescriptions.map((botDescription) => ({
          ...botDescription,
          idChat: "",
          isLoading: false,
          messages: [],
        }));
        setBots(botsInit);
        let defaultBotDescription = botsInit.find(
          (botDescription) => botDescription.display_default
        ) as botDescription;
        setSelectedBotName(defaultBotDescription.name);
      });
    } catch ($error) {
      console.log($error);
    }
  }, [authState]);

  //Gestion du loading
  const updateBotLoading = (bot: Bot, newLoadingState: boolean) => {
    // CHECKED
    setBots((prevBots) =>
      prevBots.map((b) =>
        b.name === bot.name ? { ...b, isLoading: newLoadingState } : b
      )
    );
  };

  //Gestion de l'ajout de message
  const addMessageToBot = (
    inputValue: string,
    id: string | number,
    user: string,
    bot: Bot,
    idChat: string
  ) => {
    // CHECKED
    const now = moment().format();
    idMessage = idMessage + 1;

    id = id.toString();

    let newMessage: Message = {
      user: user,
      date: now,
      contenu: inputValue,
      idChat: idChat,
      id: id,
    };
    setBots((prevBots) => {
      const updatedBots = prevBots.map((b) =>
        b.name === bot.name
          ? {
              ...b,
              idChat: newMessage.idChat,
              messages: [...b.messages, newMessage],
            }
          : b
      );
      return updatedBots;
    });
  };

  const updateBotMessages = useCallback(
    (bot: Bot, messages: Message[], idChat?: string) => {
      setBots((prevBots) =>
        prevBots.map((b) => {
          if (b.name === bot.name) {
            const updatedBot = { ...b, messages: messages };
            if (idChat) {
              updatedBot.idChat = idChat;
            }
            return updatedBot;
          }
          return b;
        })
      );
    },
    []
  );

  //Gestion de la mise à jour d'un message (modification du feedback)
  const updateBotOneMessage = (bot: Bot, newMessage: Message) => {
    // CHECKED
    let messagesUpdate = bot.messages.map((message) => {
      if (message.id === newMessage.id) {
        return { ...message, ...newMessage }; // Why not ...newMessage only ?
      } else {
        return message;
      }
    });
    updateBotMessages(bot, messagesUpdate);
  };

  //Ajout du message de l'utilsateur et calcul/ajout de la réponse du bot
  const addNewMessage = async (inputValue: string) => {
    updateBotLoading(selectedBot, true);
    addMessageToBot(
      inputValue,
      idMessage,
      config.preferredUsername,
      selectedBot,
      selectedBot.idChat
    );
    try {
      resetRef.current = false;
      const generate_response = await postGenerate(
        inputValue,
        selectedBot.name,
        selectedBot.route,
        config.clientOrganizationId,
        authState,
        selectedBot.idChat
      );
      // Check if reset has been triggered before processing the response
      if (!resetRef.current) {
        addMessageToBot(
          generate_response.response,
          generate_response.request_id,
          "bot",
          selectedBot,
          generate_response.chat_id
        );
        updateBotLoading(selectedBot, false);
      }
      // If resetRef.current is true, ignore the response
    } catch (error) {
      console.log(error);
      if (!resetRef.current) {
        // Only show error message if reset hasn't been triggered
        addMessageToBot(
          getRandomErrorMessage(),
          idMessage,
          "bot",
          selectedBot,
          selectedBot.idChat
        );
        updateBotLoading(selectedBot, false);
      }
      resetRef.current = false;
    }
  };

  // faire defiler le chatbot vers le bas lors d'un nouveau message
  const messageListRef = useRef(null);
  const scrollToBottom = () => {
    if (messageListRef.current) {
      (messageListRef.current as HTMLDivElement).scrollTop = (
        messageListRef.current as HTMLDivElement
      ).scrollHeight;
    }
  };
  //Lors de l'ajout d'un message mise à jour du scroll
  useEffect(() => {
    scrollToBottom();
  }, [selectedBot.messages.length]);
  // Update selectedBotRef when selectedBot changes
  useEffect(() => {
    selectedBotRef.current = selectedBot;
  }, [selectedBot]);

  // mettre à 0 le chatbot en fonction de la base de connaissance
  useEffect(() => {
    if (reset) {
      selectedBotRef.current.idChat = "";
      updateBotMessages(selectedBotRef.current, []);
      // TODO: Reset idChat non ?
      setReset(false);
    }
  }, [reset, updateBotMessages]);
  return (
    <StyledApp>
      <StyledHeader>
        <Header
          bot={selectedBot}
          bots={bots}
          setSelectedBotName={setSelectedBotName}
          setReset={setReset}
        />
      </StyledHeader>
      <StyledAppMain>
        {bots.length === 0 ? null : selectedBot.messages.length === 0 ? (
          <ChatAccueil
            addNewMessage={addNewMessage}
            selectedBot={selectedBot}
          ></ChatAccueil>
        ) : (
          <StyledChatbot ref={messageListRef}>
            <Chatbot
              updateBotMessage={updateBotOneMessage}
              selectedBot={selectedBot}
            />
          </StyledChatbot>
        )}
      </StyledAppMain>
      <Footer addNewMessage={addNewMessage} selectedBot={selectedBot} />
    </StyledApp>
  );
};

export default Sirius;
