import { IconButton } from "@fonciastark/duplo";
import { InputAdornment, OutlinedInput } from "@material-ui/core";
import { mdiArrowRightThinCircleOutline } from '@mdi/js';
import React, { useState } from 'react';
import styled from "styled-components";
import { Bot } from "../data/bot";

interface Props {
    addNewMessage: (inputValue: string) => void;
    selectedBot: Bot;
}

const StyledFooter = styled.div`
    position: fixed;
    width: 100%;
    bottom: 0;
    padding-top: 10px; // Adds some padding for visual spacing
`;

const StylesSearchBar = styled.div`
    margin: 0 auto; // Centers the search bar within the footer
    width: 80%; // Adjusts width to match your OutlinedInput width
    display: flex;
    justify-content: center;
`;

const DisclaimerText = styled.div`
    text-align: center;
    margin-top: 5px;
    font-size: 14px;
    color: #666;
`;

export const Footer: React.FC<Props> = ({ addNewMessage, selectedBot }) => {
    const [inputValue, setInputValue] = useState('');

    const updateInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);
    };

    const addMessage = (input: string) => {
        setInputValue("");
        addNewMessage(input);
    };

    const valueSearch = "Écrire à " + selectedBot.display_name + " . . .";

    return (
      <StyledFooter>
        <StylesSearchBar>
          <OutlinedInput
            style={{ fontSize: "16px", width: "100%" }} // Ensures the input field is opaque
            id="search-ask"
            type="text"
            value={inputValue}
            placeholder={valueSearch}
            onChange={updateInput}
            onKeyDown={(event) => {
              if (
                event.key === "Enter" &&
                !selectedBot.isLoading &&
                inputValue.length > 0
              ) {
                addMessage(inputValue);
              }
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  disabled={selectedBot.isLoading || inputValue.length === 0}
                  onClick={() => addMessage(inputValue)}
                  iconPath={mdiArrowRightThinCircleOutline}
                />
              </InputAdornment>
            }
          />
        </StylesSearchBar>
        <DisclaimerText>
          Sirius peut faire des erreurs. Pensez à vérifier les informations
          importantes
        </DisclaimerText>
      </StyledFooter>
    );
};
