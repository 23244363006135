import { Button } from "@fonciastark/duplo";
import { Menu, MenuItem } from "@material-ui/core";
import { mdiChevronDown } from "@mdi/js";
import { mdiChevronUp } from "@mdi/js/commonjs/mdi";
import Icon from "@mdi/react";
import React, { useState } from 'react';
import styled from "styled-components";
import { Bot } from "../../data/bot";
import ProfileBubble from "../commun/Profile";

interface Props {
    bot: Bot,
    bots: Bot[],
    setSelectedBotName: (inputValue: string) => void
}

const StylesContainer = styled.div`
    width: 100%;
`;

const StylesItem = styled.div`
    display: flex;
    align-items: center;
    width: 60vw;
`

const StylesContainerItem = styled.div`
    display: flex;
    flex-direction: column;
`

const StylesItemName = styled.div`
    padding-left: 10px;
    font-size: medium;
`

const StylesDescription = styled.div`
    font-size: small;
    color: #5c6a82;
    
`
export const SelectBot: React.FC<Props> = ({ bot, bots, setSelectedBotName }) => {

    //Ouverture du menu déroulant des bots
    const [open, setOpen] = useState<boolean>(false);
    //Positionnement du menu déroulant
    const [anchorEl, setAnchorEl] = useState<HTMLElement>();
    //Souris au dessus d'une base de connaissance
    const [hover, setHover] = useState<string>("");
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setOpen(!open);
    }
    const handleClose = () => {
        setOpen(false);
    };

    // mise à jour de la base de connaissance
    const updateSelectedBot = (newBot: Bot) => {
        setSelectedBotName(newBot.name);
        handleClose()
    };

    return (
        <StylesContainer >
            <Button
                onClick={handleClick} size={"xsmall"} fullWidth={true}
                style={{ background: "#317AC1", color: "#eee" }}
            >
                {bot.display_name}
                <Icon path={open?mdiChevronUp:mdiChevronDown} size={1}/>
            </Button>
            <Menu
                open={open}
                onClose={handleClose}
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                transformOrigin={{ vertical: "top", horizontal: "center" }}
            >
                {bots.map(
                    possible =>
                        <MenuItem
                            onClick={() => updateSelectedBot(possible)}
                            key={possible.name}
                            onMouseEnter={() => setHover(possible.name)}
                            onMouseLeave={() => setHover("")}
                        >
                            <StylesContainerItem>
                                <StylesItem>
                                    <ProfileBubble size={30} name={possible.name}/>
                                    <StylesItemName>{possible.display_name}</StylesItemName>
                                </StylesItem>
                                {hover===possible.name && <StylesDescription>{possible.description}</StylesDescription>}
                            </StylesContainerItem>
                        </MenuItem>
                )}
            </Menu>
        </StylesContainer>
    );
};

