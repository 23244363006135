import React from "react";
import styled from "styled-components";
import { Bot } from "../../data/bot";

interface Props {
  bot: Bot;
}

const StylesContainer = styled.div`
  width: 100%;
`;

export const FixedBot: React.FC<Props> = ({ bot }) => {
  return (
    <StylesContainer>
      <h4 style={{ background: "#317AC1", color: "#eee", textAlign: "center" }}>
        {bot.display_name}
      </h4>
    </StylesContainer>
  );
};
