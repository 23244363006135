import React from 'react';
import styled from "styled-components";
import { botDescription } from "../../api/typeApi";
import ProfileBubble from "../commun/Profile";
import { Ask } from "./Ask";

const StylesChatAccueil = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
`;

const StylesContainerTexte = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const StylesTexte = styled.div`
    font-size: medium;
    margin-top: 10px;
    color: #5c6a82;
`;

interface Props {
    addNewMessage: (inputValue : string) => void;
    selectedBot: botDescription
}

export const ChatAccueil: React.FC<Props> = ({ addNewMessage, selectedBot }) => {

    return (
        <StylesChatAccueil>
            <StylesContainerTexte>
                <ProfileBubble size={100} name={selectedBot.name}/>
                <StylesTexte>
                    {selectedBot.welcome_sentence}
                </StylesTexte>
            </StylesContainerTexte>
            <Ask addNewMessage={addNewMessage} selectedBot={selectedBot}/>
        </StylesChatAccueil>
    );
};

