import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Theme } from "@fonciastark/duplo";

ReactDOM.render(
    <React.StrictMode>
        <Theme>
            <CssBaseline />
            <App />
        </Theme>
    </React.StrictMode>,
    document.getElementById('root')
);
