import { AccessToken, IDToken } from "@okta/okta-auth-js";
import { useOktaAuth } from "@okta/okta-react";
import moment from "moment/moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { postGenerate, postListBots } from "../api/api";
import { getRandomErrorMessage } from "../api/defaults_fallback";
import { ChatAccueil } from "../components/Accueil/ChatAccueil";
import { Chatbot } from "../components/ChatBot/Chatbot";
import { FixedHeader } from "../components/FixedHeader";
import { Footer } from "../components/Footer";
import { config } from "../config";
import { Bot, Message, emptyBot } from "../data/bot";

const StyledApp = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const StyledHeader = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  height: 60px;
`;

const StyledAppMain = styled.div`
  display: flex;
  padding-top: 60px;
  height: 98vh;
  padding-bottom: 70px;
  overflow: hidden;
`;

const StyledChatbot = styled.div`
  width: 80%;
  padding-top: 10px;
  padding-bottom: 10px;
  overflow-y: auto;
  margin: 0 auto;
`;

const SiriusIT: React.FC = () => {
  const { authState } = useOktaAuth();
  const [logged, setLogged] = useState(false);
  //Si true déclenchement de la reiinitialisation du chatbot
  const [bot, setBot] = useState<Bot>(emptyBot);
  const [reset, setReset] = useState<boolean>(false);
  const resetRef = useRef(reset);

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      setLogged(false);
    } else {
      let accessToken = authState.accessToken as AccessToken;
      config.setClientAccessToken(accessToken.accessToken);
      let idToken = authState.idToken as IDToken;
      let name = idToken.claims.preferred_username as string;
      config.setPreferredUsername(name);
      setLogged(true);
    }
    console.log("logged: ", logged);
  }, [authState, logged]);

  //IdMessage global qui s'increment lors de l'ajout d'un message (key)
  let idMessage = 0;

  //Récupération des bases de connaissances et donc de la base par default
  useEffect(() => {
    // CHECKED
    const getBotDescription = async () => {
      return await postListBots(
        config.preferredUsername,
        config.clientOrganizationId,
        authState
      );
    };

    try {
      getBotDescription().then((response) => {
        const botDescriptions = response.body || [];
        const botsInit: Bot[] = botDescriptions.map((botDescription) => ({
          ...botDescription,
          idChat: "",
          isLoading: false,
          messages: [],
        }));
        let selectedBot =
          (botsInit.find(
            (oneBot) => oneBot.name === "FONCIA_IT_EMPLOYEE"
          ) as Bot) ?? emptyBot;
        setBot(selectedBot);
      });
    } catch ($error) {
      console.log($error);
    }
  }, [authState]);

  //Gestion du loading
  const updateBotLoading = (newLoadingState: boolean) => {
    setBot((prevBot) => ({ ...prevBot, isLoading: newLoadingState }));
  };

  // Update resetRef whenever reset changes to True
  useEffect(() => {
    if (reset) {
      resetRef.current = reset;
    }
  }, [reset]);

  //Gestion de l'ajout de message
  const addMessageToBot = (
    inputValue: string,
    id: string | number,
    user: string,
    idChat: string
  ) => {
    // CHECKED
    const now = moment().format();
    idMessage = idMessage + 1;

    id = id.toString();

    let newMessage: Message = {
      user: user,
      date: now,
      contenu: inputValue,
      idChat: idChat,
      id: id,
    };
    setBot((prevBot) => {
      return {
        ...prevBot,
        idChat: newMessage.idChat,
        messages: [...prevBot.messages, newMessage],
      };
    });
  };

  const updateBotMessages = useCallback(
    (messages: Message[], idChat?: string) => {
      setBot((prevBot) => {
        return {
          ...prevBot,
          messages: messages,
          idChat: idChat ? idChat : prevBot.idChat,
        };
      });
    },
    []
  );

  //Gestion de la mise à jour d'un message (modification du feedback)
  const updateBotOneMessage = (uselessBot: Bot, newMessage: Message) => {
    // CHECKED
    let messagesUpdate = bot.messages.map((message) => {
      if (message.id === newMessage.id) {
        return { ...message, ...newMessage }; // Why not ...newMessage only ?
      } else {
        return message;
      }
    });
    updateBotMessages(messagesUpdate);
  };

  //Ajout du message de l'utilsateur et calcul/ajout de la réponse du bot
  const addNewMessage = async (inputValue: string) => {
    updateBotLoading(true);
    addMessageToBot(
      inputValue,
      idMessage,
      config.preferredUsername,
      bot.idChat
    );
    try {
      resetRef.current = false;
      const generate_response = await postGenerate(
        inputValue,
        bot.name,
        bot.route,
        config.clientOrganizationId,
        authState,
        bot.idChat
      );
      // Check if reset has been triggered before processing the response
      if (!resetRef.current) {
        addMessageToBot(
          generate_response.response,
          generate_response.request_id,
          "bot",
          generate_response.chat_id
        );
        updateBotLoading(false);
      }
      // If resetRef.current is true, ignore the response
    } catch (error) {
      console.log(error);
      if (!resetRef.current) {
        // Only show error message if reset hasn't been triggered
        addMessageToBot(getRandomErrorMessage(), idMessage, "bot", bot.idChat);
        updateBotLoading(false);
      }
      resetRef.current = false;
    }
  };

  // faire defiler le chatbot vers le bas lors d'un nouveau message
  const messageListRef = useRef(null);
  const scrollToBottom = () => {
    if (messageListRef.current) {
      (messageListRef.current as HTMLDivElement).scrollTop = (
        messageListRef.current as HTMLDivElement
      ).scrollHeight;
    }
  };
  //Lors de l'ajout d'un message mise à jour du scroll
  useEffect(() => {
    scrollToBottom();
  }, [bot.messages.length]);

  // mettre à 0 le chatbot en fonction de la base de connaissance
  useEffect(() => {
    if (reset) {
      bot.idChat = "";
      updateBotMessages([]);
      // TODO: Reset idChat non ?
      setReset(false);
    }
  }, [bot, reset, updateBotMessages]);

  return (
    <StyledApp>
      <StyledHeader>
        <FixedHeader bot={bot} setReset={setReset} />
      </StyledHeader>
      <StyledAppMain>
        {bot.messages.length === 0 ? (
          <ChatAccueil
            addNewMessage={addNewMessage}
            selectedBot={bot}
          ></ChatAccueil>
        ) : (
          <StyledChatbot ref={messageListRef}>
            <Chatbot updateBotMessage={updateBotOneMessage} selectedBot={bot} />
          </StyledChatbot>
        )}
      </StyledAppMain>
      <Footer addNewMessage={addNewMessage} selectedBot={bot} />
    </StyledApp>
  );
};

export default SiriusIT;
