import React, { useState } from 'react';
import styled from "styled-components";
import { Bot, Message } from "../../data/bot";
import { Bulle } from "./Bulle";


interface Props {
    message: Message,
    updateBotMessage: (bot: Bot, message: Message) => void,
    selectedBot: Bot,
    isLatestMessage: boolean
}

const StyleBulle = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;



export const Response: React.FC<Props> = ({ message, updateBotMessage, selectedBot, isLatestMessage }) => {

    const [isHovered, setIsHovered] = useState(false);
    return (
        <div>
            <div
                onMouseOver={()=>setIsHovered(true)}
                onMouseLeave={()=>setIsHovered(false)}
                style={{
                     height: 'fixed-height',
                    }}
            >
                <StyleBulle>
                    <Bulle 
                        message={message}
                        isHovered={isHovered}
                        updateBotMessage={updateBotMessage}
                        selectedBot={selectedBot}
                        isLatestMessage={isLatestMessage}
                    />
                </StyleBulle>

            </div>
        </div>

    );
};

