import React from 'react';
import styled from "styled-components";
import { Bot, Message } from "../../data/bot";
import { Response } from "./Response";


interface Props {
    selectedBot: Bot,
    updateBotMessage: (bot: Bot, message: Message) => void,
}


const StylesResponse = styled.div`
    display: flex;
`


export const Chatbot: React.FC<Props> = ({ selectedBot, updateBotMessage }) => {
    return (
        <>
            {selectedBot.messages.map((message, index) => {
                const isLastMessage = index === selectedBot.messages.length - 1;
                    return (
                    <div key={message.id+message.date} style={{'display': 'flex'}}>
                        <StylesResponse style={{display: 'flex'}}>
                                <Response 
                                    message={message}
                                    updateBotMessage={updateBotMessage}
                                    selectedBot={selectedBot}
                                    isLatestMessage={isLastMessage}
                                />
                        </StylesResponse>
                    </div>
                        );
            }
            )}
        </>
    );
};

