import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { LoginCallback, SecureRoute, Security } from "@okta/okta-react";
import React from "react";
import { Route, BrowserRouter as Router, useHistory } from "react-router-dom";
import { config } from "./config";
import Sirius from "./pages/Sirius";
import SiriusIT from "./pages/SiriusIT";

const oktaAuth = new OktaAuth(config.okta);

const App: React.FC = () => {
  const history = useHistory();

  const restoreOriginalUri = async (
    _oktaAuth: OktaAuth,
    originalUri: string | undefined
  ) => {
    history.replace(toRelativeUrl(originalUri || "/", window.location.origin));
  };


  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <SecureRoute exact path="/" component={Sirius} />
      <SecureRoute exact path="/it" component={SiriusIT} />
      <Route path="/login/callback" component={LoginCallback} />
    </Security>
  );
};

const AppWithRouterAccess: React.FC = () => (
  <Router>
    <App />
  </Router>
);

export default AppWithRouterAccess;
