import { IconButton, Tooltip } from "@fonciastark/duplo";
import { mdiContentCopy, mdiThumbDown, mdiThumbDownOutline, mdiThumbUp, mdiThumbUpOutline } from "@mdi/js";
import { useOktaAuth } from '@okta/okta-react';
import React, { ReactElement } from 'react';
import styled from "styled-components";
import { postFeedback } from "../../api/api";
import { FeedbackThumbs, feedbackBody } from "../../api/typeApi";
import { Bot, Message } from "../../data/bot";

interface Props {
    message: Message,
    updateBotMessage: (bot: Bot, message: Message) => void,
    selectedBot: Bot
}

const StylesFeedback = styled.div`
    display: flex;
    transform: translateY(-15px)

`;

export const FeedbackResponse: React.FC<Props> = ({ message, updateBotMessage, selectedBot }) => {
    const { authState } = useOktaAuth();

    const handleIconClick = async (type:FeedbackThumbs) => {
        let feedBackData : feedbackBody = {
            "request_id": message.id,
            "feedback": type,
        }

        await postFeedback(feedBackData, authState).then(
            _ => {
                message.feedback = type
                updateBotMessage(selectedBot, message)
            }
        )
    };

    const toCopy = async (contenu : string) => {
        try {
            await navigator.clipboard.writeText(contenu);
        } catch (err) {
            console.error('Erreur lors de la copie dans le presse-papiers : ', err);
        }
    }

    const Thumb = ({type}: {type:FeedbackThumbs}) => {
        const IconComponent = type === FeedbackThumbs.THUMBS_UP ?
            message.feedback === FeedbackThumbs.THUMBS_UP ? mdiThumbUp : mdiThumbUpOutline  :
            message.feedback === FeedbackThumbs.THUMBS_DOWN ? mdiThumbDown : mdiThumbDownOutline;
        return (
            <>
                <IconButton
                    onClick={()=>handleIconClick(type)}
                    iconPath={IconComponent}
                    iconSize={0.7}
                />
            </>
        );
    };

    const IconFeedback = ({title, icon}: {title:string, icon:ReactElement}) => {

        return (
            <Tooltip title={title}>
                <div>
                    {icon}
                </div>
            </Tooltip>
        );
    };


    return (
        <StylesFeedback>
            <IconFeedback title={"Utile"} icon={
                <Thumb type={FeedbackThumbs.THUMBS_UP}/>
            }/>
            <IconFeedback title={"Peu utile"} icon={
                <Thumb type={FeedbackThumbs.THUMBS_DOWN}/>
            }/>
            <IconFeedback title={"Copier"} icon={
                <IconButton
                    onClick={() => toCopy(message.contenu)}
                    iconPath={mdiContentCopy}
                    iconSize={0.7}
                />
            }/>
        </StylesFeedback>
)
};

