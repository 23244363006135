import { IconButton, Tooltip } from "@fonciastark/duplo";
import { mdiRefresh } from "@mdi/js";
import React from "react";
import styled from "styled-components";
import { Bot } from "../data/bot";
import { FixedBot } from "./Header/FixedBot";
import ProfileBubble from "./commun/Profile";

interface Props {
  bot: Bot;
  setReset: (inputValue: boolean) => void;
}

const StylesHeader = styled.div`
  background: #317ac1;
  display: flex;
  align-items: center;
  height: 100%;
`;

const StylesIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 10px;
  padding-left: 10px;
`;

const StylesRefresh = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
`;

export const FixedHeader: React.FC<Props> = ({ bot, setReset }) => {
  return (
    <StylesHeader>
      <StylesIcon>
        <ProfileBubble size={35} name={bot.name} />
      </StylesIcon>
      <FixedBot bot={bot} />
      <Tooltip title="Réinitialisation">
        <StylesRefresh>
          <IconButton
            onClick={() => setReset(true)}
            iconPath={mdiRefresh}
            iconSize={1.5}
            color={"#e6e6fa"}
          />
        </StylesRefresh>
      </Tooltip>
    </StylesHeader>
  );
};
